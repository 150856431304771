//Imports needed for the component
import React, {useCallback, useEffect, useRef} from 'react'; // Importing the necessary modules
import '../Styles/AddEquipmentMoves.css'; // Importing CSS styles for the Admin component
import {useState} from "react"; // Importing useState hook from React
import { // Importing specific components
    NavbarLeftVirtualLibraryComponent
} from "./NavbarLeftVirtualLibraryComponent";
import IMG_Example_Profile_Stiven from "../Statics/Img/stiven.jpg";
import IMG_Example_Profile_Ander from "../Statics/Img/Ander.jpg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


// Function for sending Apis request

const Send_Apis_request = async (Url, Data, Method) => { // Function for sending update request
    let Response_Get; // Variable for storing response
    let Response_Post; // Variable for storing response
    switch (Method) {
        case  'POST':
            try {
                Response_Post = await fetch(Url, { // Sending POST request
                    method: 'POST',
                    body: JSON.stringify(Data), // Converting data to JSON format
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Post.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }

            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Post;
        case 'GET':
            try {
                Response_Get = await fetch(Url, { // Sending POST request
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json' // Setting headers
                    }
                });

                // Check if the response is not ok
                if (!Response_Get.ok) { // Handling non-ok response
                    throw new Error('Failed to update video'); // Throwing error
                }
            } catch
                (error) { // Catching errors
                console.error('Error:', error); // Logging error
                throw error; // Throwing error
            }
            return Response_Get;
        default:
            console.log("Method not found");
            break;
    }
};


// principal function
const EquipmentMovesLogs = () => {
    //----------------------------USE STATE--------------------------------
    // State variables for managing the component state--Variables de estado para gestionar el estado del componente.
    const [Global_Id_Components, setGlobal_Id_Components] = useState(0); // State variable for storing the global ID of the library
    // for storing the global installation inspection
    const [EditActive, SetEditActive] = useState(false) // State variable for managing edit mode activation
    const [AddActive, SetAddActive] = useState(false) // State variable for managing adds mode activation
    const [Components, setComponents] = useState([]); // State variable for storing Dashboards data
    const [Inventory, setInventory] = useState([]); // State variable for storing Dashboards data
    const [searchParam, setSearchParam] = useState(""); // State variable for storing search parameter
    const [Global_Date_Moved, setGlobal_Date_Moved] = useState(""); // State variable
    const [Global_Id_Job_Origin, setGlobal_Id_Job_Origin] = useState(""); // State variable
    const [Global_Id_Job_Destination, setGlobal_Id_Job_Destination] = useState(""); // State variable
    const [Global_Start_Reporting_On, setGlobal_Start_Reporting_On] = useState(""); // State variable
    const [Global_To_Stop_Reporting_On, setGlobal_To_Stop_Reporting_On] = useState(""); // State variable
    const [Global_Id_Equipment, setGlobal_Id_Equipment] = useState(""); // State variable
    const [Global_Equipmen_Description, setGlobal_Equipment_Description] = useState(""); // State variable

    const [Global_Type_of_Equipment, setGlobal_Type_of_Equipment] = useState(""); // State variable
    const [Global_Type_of_Asset, setGlobal_Type_of_Asset] = useState(""); // State variable

    //----------------------------USE REF--------------------------------
    const Description_Update_Ref = useRef(null); // Ref for the description input field in update mode
    const Description_Component_Add_Ref = useRef(null); // Ref for the Info of Component input field in added mode
    const Id_Asset_Type_Update_Ref = useRef(null); //
    const Id_Equipment_Update_Ref = useRef(null); //
    const Id_Job_Origin_Update_Ref = useRef(null); //
    const Id_Type_Equipment_Update_Ref = useRef(null); //
    const Id_Job_Destination_Update_Ref = useRef(null); //
    const Start_Reporting_On_Update_Ref = useRef(null); //
    const To_Stop_Reporting_On_Update_Ref = useRef(null); //
    const Date_Moved_Update_Ref = useRef(null); //
    const Description_Component_Update_Ref = useRef(null); // Ref for the Description input field in update mode


    const Date_Moved_Add_Ref = useRef(null); // Ref for the Date Moved input field
    const Cod_Equipment_Add_Ref = useRef(null); // Ref for the Cod Equipment input field
    const Id_Asset_Type_Add_Ref = useRef(null); // Ref for the Id Asset Type input field
    const Id_equipment_Add_Ref = useRef(null); // Ref for the Id equipment input field
    const Id_Job_Destination_Add_Ref = useRef(null); // Ref for the Id Job Destination input field
    const Start_Reporting_On_Add_Ref = useRef(null); // Ref for the Start Reporting On input field
    const To_Stop_Reporting_On_Add_Ref = useRef(null); // Ref for the To Stop Reporting On input field

    const Origin_Job_Add_Ref = useRef(null); // Ref for the Origin Job input field

    //----------------------------Functions--------------------------------

    // ******************Function for handling edit mode activation*******************************
    // ******************Función para manejar la activación del modo de edición*******************************
    const HandleEditActive = async (Id_Component, Date_Moved, Id_Equipment, Origin_Job, Destination_Job, Start_Reporting_On, To_Stop_Reporting_On) => {
        await setGlobal_Id_Components(Id_Component);
        await setGlobal_Date_Moved(Date_Moved);
        await setGlobal_Id_Equipment(Id_Equipment);
        await setGlobal_Id_Job_Origin(Origin_Job);
        await setGlobal_Id_Job_Destination(Destination_Job);
        await setGlobal_Start_Reporting_On(Start_Reporting_On);
        await setGlobal_To_Stop_Reporting_On(To_Stop_Reporting_On);

        const selectedComponent = Inventory.find(inventory =>
            inventory.Cod_Equipment === Id_Equipment
        );
        if (selectedComponent) {
            setGlobal_Id_Equipment(selectedComponent.Cod_Equipment);
            setGlobal_Type_of_Equipment(selectedComponent.Description_Equipment_Type || '');
            setGlobal_Type_of_Asset(selectedComponent.Description_Asset_Type || '');
            setGlobal_Equipment_Description(selectedComponent.Equipment_Description || '');
        }

        SetEditActive(!EditActive); // Toggle edit mode state
    };


    const HandleIdJobOriginChange = (e) => { // Function for handling description change
        setGlobal_Id_Job_Origin(e.target.value); // Update global description video state
    };

    const HandleIdJobDestinationChange = (e) => { // Function for handling description change
        setGlobal_Id_Job_Destination(e.target.value); // Update global description video state

    };


    const HandleStartReportingOnChange = (e) => { // Function for handling description change


        setGlobal_Start_Reporting_On(e.target.value); // Update global description video state

    };


    const HandleToStopReportingOnChange = (e) => { // Function for handling description change


        setGlobal_To_Stop_Reporting_On(e.target.value); // Update global description video state

    };


    const HandleSendUpdateDash = async (Id_Component) => { // Function for handling video update submission
        let response;
        const Data = {
            "Id_Equipment_Moves_Log": Id_Component,
            "Date_Moved": Date_Moved_Update_Ref.current.value,
            "Cod_Equipment": Id_Equipment_Update_Ref.current.value,
            "Id_Asset_Type": Inventory.find(inventory => inventory.Description_Asset_Type === Id_Asset_Type_Update_Ref.current.value).Id_Asset_Type,
            "Id_Equipment": Inventory.find(inventory => inventory.Cod_Equipment === Id_Equipment_Update_Ref.current.value).Id_Equipment,
            "Id_Job_Origin": parseInt(Id_Job_Origin_Update_Ref.current.value),
            "Id_Job_Destination": parseInt(Id_Job_Destination_Update_Ref.current.value),
            "Start_Reporting_On": Start_Reporting_On_Update_Ref.current.value,
            "To_Stop_Reporting_On": To_Stop_Reporting_On_Update_Ref.current.value
        };
        debugger;
        console.log(Data);
        try {
            let Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/move-equipment-log/controller-move-equipment.php?option=Update_Equipment_Move_Log'; // URL for updating video data
            await HandleEditActive(); // Activate edit mode
            response = await Send_Apis_request(
                Url, // URL for updating video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al enviable la actualization del video:', error); // Log error if update fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddDashSubmit = async (event) => { // Function for handling video addition submission
        let response;
        event.preventDefault(); // Prevent default form submission behavior
        const Data = { // Prepare data object for addition
            "Date_Moved": Date_Moved_Add_Ref.current.value,
            "Cod_Equipment": Cod_Equipment_Add_Ref.current.value,
            "Id_Asset_Type": Id_Asset_Type_Add_Ref.current.value === 'Attachment' ? 1 : Id_Asset_Type_Add_Ref.current.value === 'Auxiliary' ? 2 : 3,
            "Id_Equipment": Inventory.find(inventory => inventory.Cod_Equipment === Cod_Equipment_Add_Ref.current.value).Id_Equipment,
            "Id_Job_Origin": Origin_Job_Add_Ref.current.value,
            "Id_Job_Destination": Id_Job_Destination_Add_Ref.current.value,
            "Start_Reporting_On": Start_Reporting_On_Add_Ref.current.value,
            "To_Stop_Reporting_On": To_Stop_Reporting_On_Add_Ref.current.value
        };
        try {
            let Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/move-equipment-log/controller-move-equipment.php?option=Insert_Equipment_Move_Log'; // URL for updating video data
            await HandleAddActive(); // Activate add mode
            response = await Send_Apis_request(
                Url, // URL for insert video data
                Data,
                'POST'); // Send update request to server
        } catch (error) {
            console.error('Error al agregar el video:', error); // Log error if addition fails
        }
        response.ok ? window.location.reload() : console.log("Error al actualizar el video"); // Reload the page
    }


    const HandleAddActive = () => { // Function to toggle add mode
        SetAddActive(!AddActive); // Toggle add mode state
    }

    const HandleSearchChange = (e) => { // Function to handle search input change
        setSearchParam(e.target.value); // Update search parameter state
    }

    const ClearDashboards = () => { // Function to clear Dashboards state
        setComponents([]); // Clear Dashboards state
    };


    let fetchData;
    fetchData = useCallback(async (Description_Dashboard) => {
            try {
                let response;
                let response2;
                let Url;
                let Url2;
                //Fix the URL
                if (Description_Dashboard !== "") {
                    Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/move-equipment-log/controller-move-equipment.php?option=Get_One_Equipment_Move_Log'; // URL for fetching all categories
                    const data = {
                        "Concat_Info_Equipment_Moves_log": Description_Dashboard
                    };
                    response = await Send_Apis_request(Url, data, 'POST'); // Send request to server
                } else {
                    Url = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/move-equipment-log/controller-move-equipment.php?option=Get_All_Equipment_Moves_Log'; // URL for fetching all categories
                    response = await Send_Apis_request(Url, {}, 'GET'); // Send request to server
                    Url2 = 'https://totalcivil.com/backend.totalcivil.com/move-equipment/controllers/inventory-equipment/controller-inventory-equipment.php?option=Get_All_Equipment_Inventory'; // URL for fetching all categories
                    response2 = await Send_Apis_request(Url2, {}, 'GET'); // Send request to server
                    const data2 = await response2.json();
                    let componentsData2;
                    if (Array.isArray(data2.items)) {
                        componentsData2 = data2.items;
                    } else if (data.items) {
                        componentsData2 = [data2.items];
                    } else {
                        componentsData2 = [];
                    }
                    setInventory(componentsData2);
                    console.log(componentsData2);
                }
                const data = await response.json();


                let componentsData;


                if (Array.isArray(data.items)) {
                    componentsData = data.items;
                } else if (data.items) {
                    componentsData = [data.items];
                } else {
                    componentsData = [];
                }

                setComponents(componentsData);


                //  console.log(componentsData);

                // Check if there are more pages to fetch
                if (data.nextPageToken) {
                    // Recursively fetch more data
                    await fetchData(Description_Dashboard);
                }
            } catch (error) {
                console.error('Error fetching libraries:', error);
            }
        },
        [fetchData]);

    //----------------------------USE EFFECT--------------------------------
    useEffect(() => {
        ClearDashboards();
        fetchData(searchParam);
    }, [searchParam, fetchData]);


    const getSelectedJob = (Job) => {
        switch (Job) {
            case 'Pentagon SE Parking':
                return 1;
            case 'P-114 Walter Reed (Wet Utilities)':
                return 2;
            case 'Wargaming Center P719':
                return 3;
            case 'Microsoft IAD11':
                return 4;
            case 'Glenridge Middle School':
                return 5;
            case 'WMATA - Bladensburg':
                return 6;
            case 'NIH 40A':
                return 7;
            case 'The Stacks':
                return 8;
            case 'West Falls Church':
                return 9;
            case 'Twinbrook Quarter':
                return 10;
            case 'WMATA New Carrolton':
                return 11;
            case 'Suitland':
                return 12;
            case 'USG DC':
                return 13;
            case 'POD 6':
                return 14;
            case 'ECB4':
                return 15;
            case 'Howard Road Utities':
                return 16;
            case 'PRPA Terracota Repair':
                return 17;
            case 'Spotsylvania - (IAD 450 Off-Site Waterline)':
                return 18;
            case 'DIA - Headquarters Annex':
                return 19;
            case 'Noman Cole':
                return 20;
            case 'Tech Center at Brambleton':
                return 21;
            default:
                return '';
        }
    };
    const selectJobOrigin = getSelectedJob(Global_Id_Job_Origin);
    const selectJobDestination = getSelectedJob(Global_Id_Job_Destination);
    return (
        <div id="Navbar_Admin_Body">
            <NavbarLeftVirtualLibraryComponent/>
            <nav id="Navbar_Admin_Top">
                <button
                    onClick={HandleAddActive}
                    title="Add New"
                    className="custom-button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60px"
                        height="60px"
                        viewBox="0 0 24 24"
                        className="custom-svg"
                    >
                        <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            strokeWidth="1.5"
                        ></path>
                        <path d="M8 12H16" strokeWidth="1.5"></path>
                        <path d="M12 16V8" strokeWidth="1.5"></path>
                    </svg>
                </button>
                <form id="Form_Navbar_Top_Admin_Search">
                    <label id="Label_Navbar_Top_Admin_Search" htmlFor="search">
                        <input
                            id="Input_Navbar_Top_Admin_Search"
                            type="text"
                            required=""
                            placeholder="Search Moves"
                            className="search"
                            value={searchParam}
                            onChange={HandleSearchChange}
                        />
                        <div id="Fancy_BG_Navbar_Top_Admin_Search"></div>
                        <div id="Search_Navbar_Top_Admin_Search">
                            <svg viewBox="0 0 24 24" aria-hidden="true" id="SVG_Navbar_Top_Admin_PowerBi_Search"
                                 className=" r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                                <g>
                                    <path
                                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                                </g>
                            </svg>
                        </div>
                        <button className="close-btn" onClick={() => setSearchParam('')}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="SVG_Navbar_Top_Admin_Search"
                                 className=" h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </label>
                </form>


                {/*<section id="Section_Navbar_Top_Admin_Widgets">*/}
                {/*    <div id="Div_Navbar_Top_Admin_Widgets_Search">*/}

                {/*    </div>*/}
                {/*    <div id="Div_Navbar_Top_Admin_Widgets_Notifications">*/}
                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"*/}
                {/*             className="bi bi-bell-fill" viewBox="0 0 16 16">*/}
                {/*            <path*/}
                {/*                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*    <div id="Div_Navbar_Top_Admin_Widgets_Messages">*/}

                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="4vh" height="4vh" fill="currentColor"*/}
                {/*             className="bi bi-chat-left-text-fill" viewBox="0 0 16 16">*/}
                {/*            <path*/}
                {/*                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}

                {/*</section>*/}
                {/*<section id="Section_Navbar_Top_Admin_Profile">*/}
                {/*    <div id="Div_Navbar_Top_Admin_Profile_Info">*/}
                {/*        <div id="Div_Navbar_Top_Admin_Profile_Name">*/}
                {/*            <p style={{*/}
                {/*                fontFamily: 'cursive, serif',*/}
                {/*                fontSize: '1.5rem',*/}
                {/*                padding: '0',*/}
                {/*                margin: '0'*/}
                {/*            }}>{localStorage.getItem('Complete_Name')}</p>*/}
                {/*        </div>*/}
                {/*        <div id="Div_Navbar_Top_Admin_Profile_Type_Employee">*/}
                {/*            <p style={{fontFamily: 'inherit'}}>Admin</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {localStorage.getItem('Complete_Name') === "Stiven Mazo" ?*/}
                {/*        <div id="Div_Navbar_Top_Admin_Profile_Icon"*/}
                {/*             style={{background: `url(${IMG_Example_Profile_Stiven}) no-repeat center center / contain`}}></div>*/}
                {/*        : <div id="Div_Navbar_Top_Admin_Profile_Icon"*/}
                {/*               style={{background: `url(${IMG_Example_Profile_Ander}) no-repeat center center / contain`}}></div>}*/}
                {/*</section>*/}
            </nav>
            <div id="Div_Content_Right_Admin_PowerBi">
                <h1 id="H1_Section_Edit_Admin_PowerBi">Equipment Moves Log</h1>

                <div id="Div_Content_Right_Admin_PowerBi_Table">


                    <table id="Table_Admin_PowerBi">

                        <thead>


                        <tr>
                            <th>Id</th>
                            <th>Cod Equipment</th>
                            <th>Equipment Description</th>
                            <th>Date Moved</th>
                            <th>Origin Job</th>
                            <th>Destination Job</th>
                            <th>Start Reporting On</th>
                            <th>To Stop Reporting On</th>
                            <th>Edit</th>
                        </tr>
                        {Components.map((Component, index) => (
                            <tr id="TR_Admin_PowerBi_Virtual_Library"
                                key={`${index}-${Component.Id_Equipment_Moves_Log}`}>
                                <td>{Component.Id_Equipment_Moves_Log}</td>
                                <td>{Component.Cod_Equipment}</td>
                                <td>{Component.Equipment_Description}</td>
                                <td>{Component.Date_Moved}</td>
                                <td>{Component.Origin_Job}</td>
                                <td>{Component.Destination_Job}</td>
                                <td>{Component.Start_Reporting_On}</td>
                                <td>{Component.To_Stop_Reporting_On}</td>
                                <td>
                                    <button id="EditBtn" onClick={() => {//
                                        HandleEditActive(Component.Id_Equipment_Moves_Log, Component.Date_Moved, Component.Cod_Equipment_Inventary, Component.Origin_Job, Component.Destination_Job, Component.Start_Reporting_On, Component.To_Stop_Reporting_On);
                                    }}>
                                        <svg height="1em" viewBox="0 0 512 512">
                                            <path
                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                            ></path>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </thead>
                    </table>
                </div>
            </div>


            {AddActive && <div id="Div_Add_Equipment">
                <section id="Section_Add_Equipment">
                    <h1 id="H1_Section_Edit_Admin_PowerBi">Add Equipment Moves Log</h1>


                    <form id="Form_Div_Add_Equipment" onSubmit={HandleAddDashSubmit}>

                        <div id="Div_General_Form_Add_Equipment">
                            <div>
                                <label className="label-edit-admin" htmlFor="dateMoved">Date Moved:</label>
                                <input
                                    ref={Date_Moved_Add_Ref}
                                    type="text"
                                    className="input-edit-admin"
                                    id="dateMoved"
                                    name="dateMoved"
                                    placeholder="MM/DD/YYYY"
                                    onFocus={(e) => {
                                        e.target.type = 'date';
                                    }}
                                    onBlur={(e) => {
                                        e.target.type = 'text';
                                    }}
                                    onChange={(e) => {
                                        Date_Moved_Add_Ref.current.value = e.target.value;
                                        e.target.placeholder = e.target.value ? '' : 'MM/DD/YYYY';
                                    }}
                                />
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="description">Equipment #:</label>
                                <input
                                    ref={Cod_Equipment_Add_Ref}
                                    className="input-edit-admin"
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="Equipment #:"
                                    list="equipmentList"
                                    onChange={(e) => {
                                        const value = e.target.value.toLowerCase();
                                        const options = document.querySelectorAll('#equipmentList option');

                                        options.forEach(option => {
                                            option.style.display = option.value.toLowerCase().includes(value) ? 'block' : 'none';
                                        });

                                        // Encuentra el componente correspondiente según el valor ingresado
                                        const selectedComponent = Inventory.find(inventory =>
                                            inventory.Cod_Equipment.toLowerCase() === value
                                        );

                                        if (selectedComponent) {
                                            // Actualiza los valores de las referencias con las propiedades especificadas en `Components.map`
                                            Cod_Equipment_Add_Ref.current.value = selectedComponent.Cod_Equipment.toUpperCase();
                                            Id_Asset_Type_Add_Ref.current.value = selectedComponent.Description_Asset_Type || '';
                                            Id_equipment_Add_Ref.current.value = selectedComponent.Description_Equipment_Type || '';
                                            Description_Component_Add_Ref.current.value = selectedComponent.Equipment_Description || '';
                                        } else {
                                            // Clear the fields if no match is found
                                            Id_Asset_Type_Add_Ref.current.value = '';
                                            Id_equipment_Add_Ref.current.value = '';
                                            Description_Component_Add_Ref.current.value = '';
                                        }
                                    }}
                                />
                                <datalist id="equipmentList">
                                    {Components.map((Component, index) => (
                                        <option key={index} value={Component.Cod_Equipment_inventory}>
                                            {Component.Cod_Equipment_inventory}
                                        </option>
                                    ))}
                                </datalist>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="typeOfAsset">Type of Asset:</label>
                                <input ref={Id_Asset_Type_Add_Ref} className="input-edit-admin" type="text"
                                       id="typeOfAsset"
                                       name="typeOfAsset" placeholder="Type of Asset:"
                                       style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}/>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="typeOfEquipment">Type of Equipment:</label>
                                <input ref={Id_equipment_Add_Ref} className="input-edit-admin" type="text"
                                       id="typeOfEquipment"
                                       name="typeOfEquipment" placeholder="Type of Equipment:"
                                       style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}/>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="equipmentDescription">Equipment
                                    Description:</label>
                                <input ref={Description_Component_Add_Ref} className="input-edit-admin" type="text"
                                       id="equipmentDescription"
                                       name="equipmentDescription" placeholder="Equipment Description"
                                       style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}/>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="description">Origin Job:</label>
                                <select ref={Origin_Job_Add_Ref} className="input-edit-admin"
                                        id="description" name="description">
                                    <option value="1">Pentagon SE Parking</option>
                                    <option value="2">P-114 Walter Reed (Wet Utilities)</option>
                                    <option value="3">Wargaming Center P719</option>
                                    <option value="4">Microsoft IAD11</option>
                                    <option value="5">Glenridge Middle School</option>
                                    <option value="6">WMATA - Bladensburg</option>
                                    <option value="7">NIH 40A</option>
                                    <option value="8">The Stacks</option>
                                    <option value="9">West Falls Church</option>
                                    <option value="10">Twinbrook Quarter</option>
                                    <option value="11">WMATA New Carrolton</option>
                                    <option value="12">Suitland</option>
                                    <option value="13">USG DC</option>
                                    <option value="14">POD 6</option>
                                    <option value="15">ECB4</option>
                                    <option value="16">Howard Road Utities</option>
                                    <option value="17">PRPA Terracota Repair</option>
                                    <option value="18">Spotsylvania - (IAD 450 Off-Site Waterline)</option>
                                    <option value="19">DIA - Headquarters Annex</option>
                                    <option value="20">Noman Cole</option>
                                    <option value="21">Tech Center at Brambleton</option>
                                    <option value="22">3shopTCCE</option>
                                </select>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="destinationJob">Destination job:</label>
                                <select
                                    ref={Id_Job_Destination_Add_Ref}
                                    className="input-edit-admin"
                                    id="destinationJob"
                                    name="destinationJob"
                                >
                                    <option value="">Select a job</option>
                                    <option value="1">Pentagon SE Parking</option>
                                    <option value="2">P-114 Walter Reed (Wet Utilities)</option>
                                    <option value="3">Wargaming Center P719</option>
                                    <option value="4">Microsoft IAD11</option>
                                    <option value="5">Glenridge Middle School</option>
                                    <option value="6">WMATA - Bladensburg</option>
                                    <option value="7">NIH 40A</option>
                                    <option value="8">The Stacks</option>
                                    <option value="9">West Falls Church</option>
                                    <option value="10">Twinbrook Quarter</option>
                                    <option value="11">WMATA New Carrolton</option>
                                    <option value="12">Suitland</option>
                                    <option value="13">USG DC</option>
                                    <option value="14">POD 6</option>
                                    <option value="15">ECB4</option>
                                    <option value="16">Howard Road Utities</option>
                                    <option value="17">PRPA Terracota Repair</option>
                                    <option value="18">Spotsylvania - (IAD 450 Off-Site Waterline)</option>
                                    <option value="19">DIA - Headquarters Annex</option>
                                    <option value="20">Noman Cole</option>
                                    <option value="21">Tech Center at Brambleton</option>
                                    <option value="22">3shopTCCE</option>
                                </select>
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="startReportingOn">Start Reporting
                                    On:</label>
                                <input
                                    ref={Start_Reporting_On_Add_Ref}
                                    type="text"
                                    className="input-edit-admin"
                                    id="startReportingOn"
                                    name="startReportingOn"
                                    placeholder="MM/DD/YYYY"
                                    onFocus={(e) => {
                                        e.target.type = 'date';
                                    }}
                                    onBlur={(e) => {
                                        e.target.type = 'text';
                                    }}
                                    onChange={(e) => {
                                        Start_Reporting_On_Add_Ref.current.value = e.target.value;
                                        e.target.placeholder = e.target.value ? '' : 'MM/DD/YYYY';
                                    }}
                                />
                            </div>

                            <div>
                                <label className="label-edit-admin" htmlFor="stopReportingOn">To Stop Reporting
                                    On:</label>
                                <input
                                    ref={To_Stop_Reporting_On_Add_Ref}
                                    type="text"
                                    className="input-edit-admin"
                                    id="stopReportingOn"
                                    name="stopReportingOn"
                                    placeholder="MM/DD/YYYY"
                                    onFocus={(e) => {
                                        e.target.type = 'date';
                                    }}
                                    onBlur={(e) => {
                                        e.target.type = 'text';
                                    }}
                                    onChange={(e) => {
                                        To_Stop_Reporting_On_Add_Ref.current.value = e.target.value;
                                        e.target.placeholder = e.target.value ? '' : 'MM/DD/YYYY';
                                    }}
                                />
                            </div>
                            <section id="Div_Form_Buttons_Add_Equipment">
                                <button id="Button_Submit_Add_Equipment" type="submit">Submit</button>
                                <button id="Button_Cancel_Add_Equipment" type="button"
                                        onClick={HandleAddActive}>Cancel
                                </button>
                            </section>

                        </div>
                    </form>
                </section>
            </div>}


            {EditActive && (
                <div id="Div_Add_Equipment">
                    <section id="Section_Add_Equipment">
                        <h1 id="H1_Section_Edit_Admin_PowerBi">Edit Equipment Moves Logs</h1>
                        <form id="Form_Div_Add_Equipment" onSubmit={(e) => {
                            e.preventDefault();
                            HandleSendUpdateDash(Global_Id_Components);
                        }}>
                            <div id="Div_General_Form_Add_Equipment">
                                <div>
                                    <label className="label-edit-admin" htmlFor="description">Date Moved:</label>
                                    <input
                                        ref={Date_Moved_Update_Ref}
                                        type="text"
                                        className="input-edit-admin"
                                        id="dateMoved"
                                        name="dateMoved"
                                        placeholder="MM/DD/YYYY"
                                        onFocus={(e) => {
                                            e.target.type = 'date';
                                        }}
                                        onBlur={(e) => {
                                            e.target.type = 'text';
                                        }}
                                        onChange={(e) => {
                                            setGlobal_Date_Moved(e.target.value);
                                            e.target.placeholder = e.target.value ? '' : 'MM/DD/YYYY';
                                        }}
                                        value={Global_Date_Moved}
                                    />
                                </div>


                                <div>
                                    <label className="label-edit-admin" htmlFor="description">Equipment #:</label>
                                    <input
                                        ref={Id_Equipment_Update_Ref}
                                        className="input-edit-admin"
                                        type="text"
                                        id="description"
                                        name="description"
                                        placeholder="Equipment #:"
                                        list="equipmentList"
                                        value={Global_Id_Equipment}
                                        onChange={(e) => {
                                            setGlobal_Id_Equipment(e.target.value);
                                            const value = e.target.value.toLowerCase();
                                            const options = document.querySelectorAll('#equipmentList option');

                                            options.forEach(option => {
                                                option.style.display = option.value.toLowerCase().includes(value) ? 'block' : 'none';
                                            });
                                            // Encuentra el componente correspondiente según el valor ingresado
                                            const selectedComponent = Inventory.find(inventory =>
                                                inventory.Cod_Equipment && inventory.Cod_Equipment.toLowerCase() === value
                                            );

                                            if (selectedComponent) {
                                                // Actualiza los valores de las referencias con las propiedades especificadas en `Components.map`
                                                Id_Equipment_Update_Ref.current.value = selectedComponent.Cod_Equipment;
                                                Id_Asset_Type_Update_Ref.current.value = selectedComponent.Description_Asset_Type || '';
                                                Id_Type_Equipment_Update_Ref.current.value = selectedComponent.Description_Equipment_Type || '';
                                                Description_Component_Update_Ref.current.value = selectedComponent.Equipment_Description || '';
                                            }
                                        }}
                                    />
                                    <datalist id="equipmentList">
                                        {Components.map((Component, index) => (
                                            <option key={index} value={Component.Cod_Equipment_inventory}>
                                                {Component.Cod_Equipment_inventory}
                                            </option>
                                        ))}
                                    </datalist>
                                </div>
                                <div>
                                    <label className="label-edit-admin" htmlFor="typeOfAsset">Type of Asset:</label>
                                    <input ref={Id_Asset_Type_Update_Ref} className="input-edit-admin" type="text"
                                           id="typeOfAsset"
                                           name="typeOfAsset" placeholder="Type of Asset:"
                                           value={Id_Asset_Type_Update_Ref.current ? Id_Asset_Type_Update_Ref.current.value : Global_Type_of_Asset}
                                           style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}
                                    />
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="typeOfEquipment">Type of
                                        Equipment:</label>
                                    <input ref={Id_Type_Equipment_Update_Ref} className="input-edit-admin" type="text"
                                           id="typeOfEquipment"
                                           name="typeOfEquipment" placeholder="Type of Equipment:"
                                           value={Id_Type_Equipment_Update_Ref.current ? Id_Type_Equipment_Update_Ref.current.value : Global_Type_of_Equipment}
                                           style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}/>
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="equipmentDescription">Equipment
                                        Description:</label>
                                    <input ref={Description_Component_Update_Ref} className="input-edit-admin"
                                           type="text"
                                           id="equipmentDescription"
                                           name="equipmentDescription" placeholder="Equipment Description"
                                           value={Description_Component_Update_Ref.current ? Description_Component_Update_Ref.current.value : Global_Equipmen_Description}
                                           style={{pointerEvents: 'none', backgroundColor: 'none', border: 'none'}}/>
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="Origin Job">Origin Job:</label>
                                    <select
                                        ref={Id_Job_Origin_Update_Ref}
                                        className="input-edit-admin"
                                        id="originJob"
                                        name="originJob"
                                        value={Global_Id_Job_Origin}
                                        onChange={HandleIdJobOriginChange}
                                    >
                                        <option value={selectJobOrigin}>{Global_Id_Job_Origin}</option>
                                        <option value="1">Pentagon SE Parking</option>
                                        <option value="2">P-114 Walter Reed (Wet Utilities)</option>
                                        <option value="3">Wargaming Center P719</option>
                                        <option value="4">Microsoft IAD11</option>
                                        <option value="5">Glenridge Middle School</option>
                                        <option value="6">WMATA - Bladensburg</option>
                                        <option value="7">NIH 40A</option>
                                        <option value="8">The Stacks</option>
                                        <option value="9">West Falls Church</option>
                                        <option value="10">Twinbrook Quarter</option>
                                        <option value="11">WMATA New Carrolton</option>
                                        <option value="12">Suitland</option>
                                        <option value="13">USG DC</option>
                                        <option value="14">POD 6</option>
                                        <option value="15">ECB4</option>
                                        <option value="16">Howard Road Utities</option>
                                        <option value="17">PRPA Terracota Repair</option>
                                        <option value="18">Spotsylvania - (IAD 450 Off-Site Waterline)</option>
                                        <option value="19">DIA - Headquarters Annex</option>
                                        <option value="20">Noman Cole</option>
                                        <option value="21">Tech Center at Brambleton</option>
                                        <option value="22">3shopTCCE</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="Destination Job">Destination
                                        Job:</label>
                                    <select
                                        ref={Id_Job_Destination_Update_Ref}
                                        className="input-edit-admin"
                                        id="destinationJob"
                                        name="destinationJob"
                                        value={Global_Id_Job_Destination}
                                        onChange={HandleIdJobDestinationChange}
                                    >
                                        <option value={selectJobDestination}>{Global_Id_Job_Destination}</option>
                                        <option value="1">Pentagon SE Parking</option>
                                        <option value="2">P-114 Walter Reed (Wet Utilities)</option>
                                        <option value="3">Wargaming Center P719</option>
                                        <option value="4">Microsoft IAD11</option>
                                        <option value="5">Glenridge Middle School</option>
                                        <option value="6">WMATA - Bladensburg</option>
                                        <option value="7">NIH 40A</option>
                                        <option value="8">The Stacks</option>
                                        <option value="9">West Falls Church</option>
                                        <option value="10">Twinbrook Quarter</option>
                                        <option value="11">WMATA New Carrolton</option>
                                        <option value="12">Suitland</option>
                                        <option value="13">USG DC</option>
                                        <option value="14">POD 6</option>
                                        <option value="15">ECB4</option>
                                        <option value="16">Howard Road Utities</option>
                                        <option value="17">PRPA Terracota Repair</option>
                                        <option value="18">Spotsylvania - (IAD 450 Off-Site Waterline)</option>
                                        <option value="19">DIA - Headquarters Annex</option>
                                        <option value="20">Noman Cole</option>
                                        <option value="21">Tech Center at Brambleton</option>
                                        <option value="22">3shopTCCE</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="description">Start Reporting
                                        On:</label>
                                    <input
                                        ref={Start_Reporting_On_Update_Ref}
                                        className="input-edit-admin"
                                        type="text"
                                        id="startReportingOn"
                                        name="startReportingOn"
                                        placeholder="Start Reporting On"
                                        onFocus={(e) => {
                                            e.target.type = 'date';
                                        }}
                                        onBlur={(e) => {
                                            e.target.type = 'text';
                                        }}
                                        value={Global_Start_Reporting_On}
                                        onChange={HandleStartReportingOnChange}
                                    />
                                </div>

                                <div>
                                    <label className="label-edit-admin" htmlFor="To Stop Reporting On">To Stop Reporting
                                        On:</label>
                                    <input
                                        ref={To_Stop_Reporting_On_Update_Ref}
                                        className="input-edit-admin"
                                        type="text"
                                        id="startReportingOn"
                                        name="startReportingOn"
                                        placeholder="Start Reporting On"
                                        onFocus={(e) => {
                                            e.target.type = 'date';
                                        }}
                                        onBlur={(e) => {
                                            e.target.type = 'text';
                                        }}
                                        value={Global_To_Stop_Reporting_On}
                                        onChange={HandleToStopReportingOnChange}
                                    />
                                </div>
                                <section id="Div_Form_Buttons_Add_Equipment">
                                    <button id="Button_Submit_Add_Equipment" type="submit">Submit</button>
                                    <button id="Button_Cancel_Add_Equipment" type="button"
                                            onClick={HandleEditActive}>Cancel
                                    </button>
                                </section>
                            </div>
                        </form>
                    </section>
                </div>
            )}
        </div>
    );
}

export default EquipmentMovesLogs;
